<template>
  <div id="container">
    <b-form @submit.prevent="save">
      <table class="w-100">
        <thead>
        <tr>
          <th>Reporte</th>
          <th>Archivo</th>
          <th>Opciones</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(report, index) in reports" :key="report.id">
          <td>{{report.id}}</td>
          <td>{{report.filename}}</td>
          <td>
            <b-button variant="primary" @click="edit(index)">Editar</b-button>
          </td>
        </tr>
        <tr>
          <td>
            <b-input v-model="report.id"></b-input>
          </td>
          <td>
            <single-file-upload v-if="report.id" @change="setFilename" selection-mode="single" :id="'reports'"
                                :name="report.id"></single-file-upload>
          </td>
          <td>
            <b-button type="submit" variant="info">Save</b-button>
          </td>
        </tr>
        </tbody>
      </table>
      <pre>
      {{report}}
      </pre>

    </b-form>
  </div>
</template>

<script>
import SingleFileUpload from "@/components/SingleFileUpload";
import Reports from "@/reports/index";

export default {
  name: "ReportsComponent",
  components: {SingleFileUpload},
  async mounted() {
    this.reports = (await Reports.findAll()).map(x => {return {id: x.id, filename: x.filename}});
  },
  data() {
    return {
      reports: [],
      report: {id: null, filename: null}
    }
  },
  methods: {
    add() {
      this.reports.push({id: null, filename: null})
    },
    edit(idx) {
      this.report = this.reports[idx];
    },
    setFilename(val) {
        this.report.filename = val[0];
    },
    save() {
      Reports.save(this.report);
    }
  }
}
</script>

<style scoped>

</style>